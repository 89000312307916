import React from "react";
import "../css/Logo.css";

function Logo() {
  return (
    <svg version="1.1" viewBox="0 0 512 512" className="logo-svg">
      <g>
        <path
          id="blade-1"
          d="M437.025,74.993c-4.903-4.894-10.178-9.735-15.696-14.396l-131.739,76.05l222.224,128.279
		c0.126-3.066,0.186-6.028,0.186-8.922C512,187.629,485.37,123.342,437.025,74.993z"
        />
        <path
          id="blade-2"
          d="M255.996,0.009c-29.666,0-58.77,5.067-86.56,15.063v152.168L391.677,38.943
		C351.028,13.451,304.263,0.009,255.996,0.009z"
        />
        <path
          id="blade-3"
          d="M135.858,29.934c-22.452,11.936-42.914,27.068-60.9,45.058c-37.224,37.245-61.706,84.035-70.913,135.469
		l131.813,76.11V29.934z"
        />
        <path
          id="blade-4"
          d="M0.182,247.014C0.06,250.088,0,253.076,0,256.004c0,68.376,26.626,132.663,74.967,181.012
		c4.85,4.851,10.126,9.684,15.704,14.387l131.718-76.058L0.182,247.014z"
        />
        <path
          id="blade-5"
          d="M255.996,511.991c29.67,0,58.8-5.067,86.634-15.08V344.726L120.324,473.057
		C160.973,498.548,207.737,511.991,255.996,511.991z"
        />
        <path
          id="blade-6"
          d="M376.167,307.367h0.044V482.03c22.39-11.918,42.82-27.032,60.814-45.006
		c37.219-37.245,61.706-84.061,70.93-135.546l-131.788-76.11V307.367z"
        />
      </g>
    </svg>
  );
}

export default Logo;
